@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "AvenirLTStd-Black";
  src: url("./fonts/AvenirLTStd-Black.otf");
}
@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url("./fonts/AvenirLTStd-Roman.otf");
}
@font-face {
  font-family: "Rheiborn_Sans_Clean";
  src: url("./fonts/Rheiborn_Sans_Clean.otf");
}

@font-face{
  font-family: "NeueKabel";
  src: url("./fonts/NeueKabel.otf");
}
@font-face{
  font-family: "NeueKabel-Book";
  src: url("./fonts/NeueKabel-Book.otf");
}

@font-face {
  font-family: "Azonix";
  src: url("./fonts/Azonix-1VB0.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Azonix_text";
  src: url("./fonts/Azonix-1VB0.otf");
  font-weight: 400;
}

@font-face {
  font-family: "MyriadPro";
  src: url("./fonts/MyriadProRegular.ttf");
  font-weight: lighter;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background: url(https://github.com/KSHITIJ-2022/media/blob/master/RelicHunter/Alter-back.png?raw=true);
  background-size:fill;
  background-repeat: repeat;
  background-position: center/cover;
  height: 100vh;
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */

}

body::-webkit-scrollbar {
  width:1px;
  background-color: rgba(0, 0, 0, 0.001);
  border-radius: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  border-radius: 0px;
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.005); 
  border-radius: 0px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: rgba(236, 233, 233, 0.603); 
}